/*global gsap, ScrollTrigger, ajax_object*/
// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Hero svg animation
   */

  $('.svg-item').each(function (index) {
    $(this)
      .delay(500 * index)
      .queue(function (next) {
        $(this)
          .css('transform', 'unset')
          .css('opacity', '1')
          .css('filter', 'unset');
        next();
      });
  });

  /**
   * Testimonials Slider
   */
  function updateMatchHeight() {
    $('.testimonials-slider__item, .blog-slider .post-item').addClass(
      'matchHeight'
    );
    $('.matchHeight').matchHeight();
  }

  $('.testimonials-slider').slick({
    infinite: true,
    dots: true,
    speed: 1250,
    prevArrow: '<div class="arrow-slider arrow-slider-prev"></div>',
    nextArrow: '<div class="arrow-slider arrow-slider-next"></div>',
  });

  $('.blog-slider').slick({
    slidesToShow: 3,
    infinite: true,
    dots: true,
    speed: 1250,
    prevArrow: '<div class="arrow-slider arrow-slider-prev"></div>',
    nextArrow: '<div class="arrow-slider arrow-slider-next"></div>',
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  // Initial update
  updateMatchHeight();

  // Update on window resize
  $(window).resize(function () {
    updateMatchHeight();
  });

  /**
   * Practice Areas Scrolling Animation
   */

  if (window.innerWidth >= 1024) {
    gsap.registerPlugin(ScrollTrigger);

    let sections = gsap.utils.toArray('.areas__items');

    gsap.to(sections, {
      xPercent: -112,
      x: () => window.innerWidth,
      ease: 'none',
      scrollTrigger: {
        pin: '.areas__wrapper',
        // markers: { startColor: 'green', endColor: 'red' },
        trigger: '.areas',
        start: 'left left',
        scrub: 1.25,
      },
    });
  }

  /**
   * Main Header Menu
   */

  const menuItems = document.querySelectorAll('.menu-item-has-children');

  menuItems.forEach((menuItem) => {
    const drop = document.createElement('span');
    drop.classList.add('drop');

    const menuLink = menuItem.querySelector('a');
    if (menuLink) {
      menuItem.insertBefore(drop, menuLink.nextSibling);
    }

    drop.addEventListener('click', function (event) {
      event.preventDefault();

      const nextElement = drop.nextElementSibling;

      if (nextElement && nextElement.classList.contains('mega-menu')) {
        nextElement.classList.toggle('active');

        if (nextElement.classList.contains('active')) {
          setTimeout(() => {
            const megaItem = nextElement.querySelector('.mega-item');
            if (megaItem) {
              megaItem.classList.add('is-active');
              const parentMegaItem = menuItem.closest('.mega-item');
              if (parentMegaItem) {
                parentMegaItem.classList.add('inactive');
              }
            }
          }, 1);
        } else {
          const megaItem = nextElement.querySelector('.mega-item');
          if (megaItem) {
            megaItem.classList.remove('is-active');
            const parentMegaItem = menuItem.closest('.mega-item');
            if (parentMegaItem) {
              parentMegaItem.classList.remove('inactive');
            }
          }
        }
      }
    });
  });

  const menuTitles = document.querySelectorAll('.menu-title');

  menuTitles.forEach((title) => {
    title.addEventListener('click', function (event) {
      const afterElement = window.getComputedStyle(title, ':after');
      const rect = title.getBoundingClientRect();
      const clickX = event.clientX;
      const afterWidth = parseInt(afterElement.width);

      // Check if click is on pseudo-element :after
      if (clickX >= rect.right - afterWidth) {
        event.preventDefault();

        // Remove active class from the parent mega menu
        const megaMenu = title.closest('.mega-menu');
        if (megaMenu && megaMenu.classList.contains('active')) {
          setTimeout(() => {
            megaMenu.classList.remove('active');
          }, 1);

          const megaItem = megaMenu.querySelector('.mega-item');
          if (megaItem) {
            megaItem.classList.remove('is-active');
          }

          // Remove class unective from the parent .mega-item
          const parentMegaItem = megaMenu
            .closest('.menu-item-has-children')
            .closest('.mega-item');
          if (parentMegaItem) {
            setTimeout(() => {
              parentMegaItem.classList.remove('inactive');
            }, 1);
          }
        }
      }
    });
  });

  /**
   * Hover Menu Item
   */

  if (window.innerWidth >= 1024) {
    $('.header .menu-item-has-children').hover(
      function () {
        clearTimeout($(this).data('timeoutId'));
        $(this).find('a').addClass('hovered');
      },
      function () {
        var $this = $(this);
        var timeoutId = setTimeout(function () {
          $this.find('a').removeClass('hovered');
        }, 200);
        $(this).data('timeoutId', timeoutId);
      }
    );

    const dropdownLinks = document.querySelectorAll(
      '.desktop-menu > .has-dropdown > a'
    );

    dropdownLinks.forEach((dropdownLink) => {
      const parentDropdown = dropdownLink.closest('.has-dropdown');
      const megaMenu = parentDropdown.querySelector('.mega-menu');
      const swaps = parentDropdown.querySelectorAll('.swap');
      let timer;

      function showSwaps() {
        // Удаляем классы inactive, is-active, active
        document
          .querySelectorAll('.inactive, .is-active, .active')
          .forEach((element) => {
            element.classList.remove('inactive', 'is-active', 'active');
          });

        // Оставшаяся часть функции showSwaps
        swaps.forEach((swap) => swap.classList.remove('swap'));
        setTimeout(() => {
          swaps.forEach((swap) => (swap.style.opacity = '1'));
          swaps.forEach((swap) => (swap.style.filter = 'blur(0)'));
        }, 1);
      }

      function hideSwaps() {
        timer = setTimeout(() => {
          swaps.forEach((swap) => {
            swap.style.opacity = '0';
            swap.style.filter = 'blur(2.5rem)';
            setTimeout(() => {
              swap.classList.add('swap');
            }, 200);
          });
        }, 200);
      }

      dropdownLink.addEventListener('mouseenter', function () {
        clearTimeout(timer);
        showSwaps();
      });

      megaMenu.addEventListener('mouseenter', function () {
        clearTimeout(timer);
      });

      dropdownLink.addEventListener('mouseleave', hideSwaps);
      megaMenu.addEventListener('mouseleave', hideSwaps);
    });
  }

  /**
   * Mobile Click Menu Item
   */

  if (window.innerWidth < 1024) {
    const dropdownLinks = document.querySelectorAll(
      '.desktop-menu > .has-dropdown'
    );

    dropdownLinks.forEach((dropdownLink) => {
      const arrowSpan = document.createElement('span');
      arrowSpan.classList.add('open');
      dropdownLink.appendChild(arrowSpan);

      arrowSpan.addEventListener('click', showSwaps);

      const parentDropdown = dropdownLink.closest('.has-dropdown');
      const swaps = parentDropdown.querySelectorAll('.swap');
      function showSwaps() {
        swaps.forEach((swap) => swap.classList.remove('swap'));
        setTimeout(() => {
          swaps.forEach((swap) => (swap.style.opacity = '1'));
          swaps.forEach((swap) => (swap.style.filter = 'blur(0)'));
        }, 1);
      }
    });

    const menuTitles = document.querySelectorAll('.menu-title');

    menuTitles.forEach((menuTitle) => {
      const linkTitle = menuTitle.querySelector('.link-title');

      if (linkTitle) {
        const closeSpan = document.createElement('span');
        closeSpan.className = 'close';

        linkTitle.insertAdjacentElement('afterend', closeSpan);

        $('.close').on('click', function () {
          const megaMenu = $('.mega-menu');

          setTimeout(function () {
            megaMenu.addClass('swap');
          }, 400);
          megaMenu.css({
            opacity: '0',
            filter: 'blur(12px)',
          });
        });
      }
    });
  }

  /**
   * Blog loadmore
   */
  let postPerPage = 12;

  function loadScrollPosts(postType, response, taxonomy = false) {
    let offset = taxonomy ? 0 : $('.post__wrapper').length;
    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: ajax_object.ajax_url,
      data: {
        postType: postType,
        action: 'loadmore',
        offset: offset,
        postperpage: postPerPage,
      },
      beforeSend: function () {
        $('.ajax-loading').hide();
      },
      success: function (response) {
        $('.ajax-loading').hide();
        $('.ajax-posts').append(response.html);

        // Check if all posts have been loaded
        if ($('.post__wrapper').length < response.post_count) {
          $('.ajax-loading').show(); // Show loading indicator for more posts
        } else {
          $('.post-load').hide(); // Hide the load more button if no more posts
        }
      },
      error: function (result) {
        console.warn(result);
      },
    });
    return false;
  }

  $('.post-load').click(function () {
    let postType = $(this).data('post-type');
    loadScrollPosts(postType);
  });

  $('.current').click(function () {
    $('.practice-servise-menu').slideToggle();
    $(this).toggleClass('active');
  });
  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  ScrollOut({
    cssProps: {
      visibleY: true,
      viewportY: true,
      scrollPercentY: true,
    },
    threshold: 0.97,
    once: true,
    onShown: function (element) {
      if ($(element).is('.ease-order')) {
        $(element)
          .find('.ease-order__item')
          .each(function (i) {
            let $this = $(this);
            $(this).attr('data-scroll', '');
            window.setTimeout(function () {
              $this.attr('data-scroll', 'in');
            }, 300 * i);
          });
      }
    },
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
